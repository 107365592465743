<template>
	<div>
        <div class="mb-1 d-flex justify-content-between">
            <h6 class="mb-0">{{msgContent.subject}}</h6>
            <b-button @click="goBack" variant="outline-primary"><i class="ri-arrow-go-back-line"></i></b-button>
        </div>
        <div class="mb-1 small"><b>{{$t('date')}}:</b> {{msgContent.updated_at}}</div>
        <div class="mb-1 small"><b>{{$t('from')}}:</b> {{msgContent.sender}}</div>
        <div class="mb-1 small"><b>{{$t('to')}}:</b> {{msgContent.to}}</div>
        <div v-if="msgContent.is_lock == 1" class="mb-3 small"><i class="ri-lock-fill"></i> {{$t('message_lock')}}</div>
        <div v-if="msgContent.is_lock == 0" class="mb-3 small"><i class="ri-lock-unlock-line"></i> {{$t('message_unlock')}}</div>
        <div class="mb-4">
            <div v-html="decodeHtml(msgContent.message)"></div>
        </div>
        <div v-for="(itm, key) in msgContent.messages" :key="key" class="border rounded mb-4 p-3">
            <div class="mb-1 small"><b>{{$t('date')}}:</b> {{formatDate(itm.updated_at)}}</div>
            <div class="mb-1 small"><b>{{$t('from')}}:</b> {{itm.sender}}</div>
            <div class="mb-1 small"><b>{{$t('to')}}:</b> {{itm.to}}</div>
            <div v-html="decodeHtml(itm.message)"></div>
        </div>
        <div class="mb-4">
            <b-card-group deck>
            <b-card
                v-for="(itm, key) in msgContent.files"
                :key="key"
                :footer="$t('download')"
                footer-tag="footer"
                footer-bg-variant="primary"
                footer-border-variant="dark"
                style="max-width: 10rem;"
                class="text-center pointer"
                @click="downloadFile(itm)">
                <b-card-text><i class="ri-download-2-fill"></i><br>{{itm.name}}</b-card-text>
            </b-card>
            </b-card-group>
        </div>
        <div v-if="msgEditorShow" class="border rounded mb-4 p-3">
            <ValidationObserver ref="answerForm">
                <ValidationProvider name="message" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('answer')">
                        <ck-editor-input
                            v-model="answerMsg"
                            :validateError="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
            <b-button @click="sendAnswer" class="mt-2" variant="primary">{{$t('send')}}</b-button>
            </ValidationObserver>
        </div>
        <div class="d-flex">
            <b-button
                v-if="msgContent.is_lock == 0"
                @click="showEditor"
                type="submit"
                variant="outline-primary"
                class="mr-2 btn-sm">{{msgEditorShow ? $t('cancel') : $t('answer_force')}}</b-button>
            <b-button
                v-if="checkPermission('message_delete')"
                @click="deleteMessage"
                type="submit"
                variant="outline-danger"
                class="mr-2 btn-sm">{{$t('delete')}}</b-button>
        </div>
    </div>
</template>
<script>

import MessageService from "@/services/MessageService";
import moment from "moment";
import CkEditorInput from "@/components/elements/CkEditorInput";
import {ValidationObserver, ValidationProvider} from "vee-validate"

	export default {
        props: {
            msgId: {
                type: String,
                default: null,
            }
        },
		components: {
            CkEditorInput,
            ValidationObserver,
            ValidationProvider

		},

		data() {
			return {
                msgContent: {},
                answerMsg: null,
                msgEditorShow: false
			}
		},
		created() {

		},
        watch: {
            msgId: {
                handler(value){
                    if(value != null){
                        this.getMessage(value)
                    }
                }
            }
        },
		methods: {
            goBack(){
                this.$emit('goBack', true)
            },

            getMessage(id){
                MessageService.showMessage(id).then(response => {
                    this.msgContent = response.data.data[0]
                    this.msgContent.updated_at = moment(this.msgContent.updated_at).format("DD.MM.YYYY HH:MM")
                }).catch(e => {})
            },

            formatDate(date){
                return moment(date).format("DD.MM.YYYY HH:MM")
            },

            decodeHtml(html) {
                let txt = document.createElement("textarea");
                txt.innerHTML = html;
                return txt.value;
            },

            showEditor() {
                this.msgEditorShow = this.msgEditorShow ? false : true
            },

            async sendAnswer(){
                const isValid = await this.$refs.answerForm.validate();
                if (isValid) {
                    let formData = {
                        parent_id: this.msgId,
                        message: this.answerMsg
                    }
                    MessageService.answerMessage(formData).then(response => {
                        this.$toast.success(this.$t('success_send_message'))
                        this.showEditor()
                        this.getMessage(this.msgId)
                        this.answerMsg = null
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                }
            },

            deleteMessage(){
                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('message_delete_confirm'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {
                        MessageService.deleteMessage(this.msgId).then(response => {
                            this.$toast.success(this.$t('api.'+response.data.message))
                            this.$emit('goBack', true)
                        }).catch(e => {
                            this.$toast.error(this.$t('api.'+e.data.message))
                        })
                    }
                })
            },

            downloadFile(obj){
                if(this.checkPermission('message_showmedia')){
                    MessageService.showMedia(obj._id).then(response => {
                        this._downloadFile(response, obj.name)
                    }).catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
		}
	}
</script>
<style scoped>
.pointer {cursor: pointer;}
</style>

