<template>
    <div>
        <ckeditor
            @input="handleInput"
            :config="editorConfig"
            v-model="text" />
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    export default {
        name: 'CkEditorInput',
        props: {
            value: {
                type: String,
                default: null
            },
            validateError: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                editorConfig: {
                    language: this.$i18n.locale,
                    toolbarGroups: [{
                        "name": "basicstyles",
                        "groups": ["basicstyles"]
                    },
                        {
                            "name": "links",
                            "groups": ["links"]
                        },
                        {
                            "name": "paragraph",
                            "groups": ["list", "blocks"]
                        },
                        {
                            "name": "document",
                            "groups": ["mode"]
                        },
                        {
                            "name": "insert",
                            "groups": ["insert"]
                        },
                        {
                            "name": "styles",
                            "groups": ["styles"]
                        },
                        {
                            "name": "about",
                            "groups": ["about"]
                        }
                    ],
                    // Remove the redundant buttons from toolbar groups defined above.
                    removeButtons: 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,PasteFromWord'
                },
                text: null
            };
        },

        watch: {
            value: {
                handler(val){
                    this.text = val
                }
            }
        },
        methods: {
            handleInput(event) {
                this.$emit('input',event)
            }
        },
        created() {
            this.text = this.value
        }
    };
</script>
