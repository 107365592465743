<template>
	<div>
        <div class="row">
            <div class="d-none d-xl-inline-block col-xl-12 col-xxl-12">
                <div class="overflow-y-scroll overflow-x-hidden w-100 d-none d-lg-block position-sticky" style="height: 550px;">
                    <b-button
                        v-for="(itm, key) in btnArea" :key="key"
                        variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left"
                        :class="[selectedButton === itm.id ? 'btn-outline-primary' : 'secondary-border-lighter btn-outline-secondary']"
                        @click="goToDetail(itm.id)">
                        <div class="mail-explanation mr-3">
                            <div>{{itm.sender}}</div>
                            <div class="small ellipsis-1 text-primary">{{itm.subject}}</div>
                            <div v-if="itm.is_lock == 1" class="small ellipsis-1"><i class="ri-lock-fill"></i> {{$t('closed')}}</div>
                            <div v-if="itm.is_lock == 0" class="small ellipsis-1"><i class="ri-lock-unlock-line"></i> {{$t('open')}}</div>
                        </div>
                        <div class="mail-time d-flex align-items-center">{{ itm.updated_at }}</div>
                    </b-button>
                </div>
            </div>
            <div class="col-12">
                <b-form-group label="" class="d-block d-xl-none pb-2 pb-xl-0">
                    <template>
                        <div>
                            <multiselect
                                v-model="selectedButton"
                                :options="msgOptions"
                                @input="goToDetail"
                                label="text"
                                track-by="value"
                                :multiple="false"
                                :select-label="''"
                                :selected-label="''"
                                :deselect-label="''"
                                :placeholder="$t('select')"
                                :searchable="true">
                                <span slot="noOptions">
                                    {{ $t('no_options') }}
                                </span>
                            </multiselect>
                        </div>
                    </template>
                </b-form-group>
            </div>
        </div>
    </div>
</template>
<script>

import MessageService from "@/services/MessageService";
import moment from "moment";

	export default {
        props: {
            currentBox: {
                type: Number,
                default: 0
            }
        },
		components: {


		},

		data() {
			return {
                btnArea: [],

                selectedButton: null,
                msgOptions: [

                ],
			}
		},
		created() {
            this.getMessages(this.currentBox)
		},
        watch: {
            currentBox: {
                handler(value) {
                    this.getMessages(value)
                }
            }
        },
		methods: {
            getMessages(currentBox){
                this.msgOptions = []
                this.btnArea = []
                if(currentBox == 0){
                    MessageService.userInbox().then(response => {
                        let data = response.data.data

                        data.forEach(itm => {
                            let date = ''
                            if(moment(new Date()).format('DD.MM.YYYY') == moment(itm.last_message.updated_at).format('DD.MM.YYYY')) {
                                date = moment(itm.last_message.updated_at).format('HH:MM')
                            } else {
                                date = moment(itm.last_message.updated_at).format('DD.MM.YYYY')
                            }

                            let lock = itm.last_message.is_lock == 1 ? this.$t('closed') : this.$t('open')
                            this.msgOptions.push({
                                text: '"'+itm.last_message.sender+'" - '+itm.last_message.subject+' - '+lock,
                                value: itm.last_message.parent_id != null ? itm.last_message.parent_id : itm.message_id
                            })
                            this.btnArea.push({
                                id: itm.last_message.parent_id != null ? itm.last_message.parent_id : itm.message_id,
                                sender: itm.last_message.sender,
                                subject: itm.last_message.subject,
                                updated_at:  date,
                                is_lock: itm.last_message.is_lock
                            })
                        })
                    }).catch(e => {})
                } else if(currentBox == 1){
                    MessageService.userOutbox().then(response => {
                        let data = response.data.data
                        data.forEach(itm => {
                            let date = ''
                            if(moment(new Date()).format('DD.MM.YYYY') == moment(itm.updated_at).format('DD.MM.YYYY')) {
                                date = moment(itm.updated_at).format('HH:MM')
                            } else {
                                date = moment(itm.updated_at).format('DD.MM.YYYY')
                            }

                            let lock = itm.is_lock == 1 ? this.$t('closed') : this.$t('open')

                            this.msgOptions.push({
                                text: '"'+itm.sender+'" - '+itm.subject+' - '+lock,
                                value: itm.id
                            })

                            this.btnArea.push({
                                id: itm.parent_id != null ? itm.parent_id : itm.id,
                                sender: itm.sender,
                                subject: itm.subject,
                                updated_at:  date,
                                is_lock: itm.is_lock

                            })
                        })
                    }).catch(e => {})
                }

            },

            goToDetail(id){
                if(this.checkPermission('message_show')){
                    this.selectedButton = id
                    this.$emit('getDetailId',id)
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
		}
	}
</script>

