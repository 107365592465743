<template>
	<div>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
                   :title="$t('outbox')"
        ></datatable>
    </div>
</template>
<script>

import MessageService from "@/services/MessageService";
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import moment from "moment";
import qs from "qs";

	export default {
        props: {

        },
		components: {
            DatatableFilter,
            Datatable

		},

		data() {
			return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('view'),
                                    class: 'ri-search-eye-line',
                                    permission: "message_show",
                                    callback: (row) => {
                                        let id = row.parent_id != null ? row.parent_id : row.id
                                        this.goToDetail(id)
                                    },

                                },
                            ]
                        },
                        {
                            label: this.$t('to'),
                            field: 'to',
                            hidden: false,
                        },
                        {
                            label: this.$t('subject'),
                            field: 'subject',
                            hidden: false
                        },
                        {
                            label: this.$t('date'),
                            field: 'updated_at',
                            hidden: false,
                            formatFn: (value) => {
                                let date = ''
                                if(moment(new Date()).format('DD.MM.YYYY') == moment(value).format('DD.MM.YYYY')) {
                                    date = moment(value).format('HH:MM')
                                } else {
                                    date = moment(value).format('DD.MM.YYYY')
                                }
                                return date
                            }
                        },
                        {
                            label: '',
                            field: 'is_lock',
                            hidden: false,
                            formatFn: (value) => {
                                return value == 1 ? this.$t('closed') : this.$t('open')
                            }
                        },

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'code',
                        page: 1,
                        limit: 20
                    }
                },

			}
		},
		created() {
            this.getRows()
		},
        watch: {

        },
		methods: {
            filterSet() {
                this.datatable.queryParams.filter = {
                    code: null,
                    name: null
                }
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows(tab){

                    this.datatable.showTable = true;
                    this.datatable.isLoading = true;

                    const config = {
                        params: {
                            ...this.datatable.queryParams
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };

                    return MessageService.userOutbox(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data
                                             this.datatable.total = response.data.pagination.total

                                             this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         });

            },

            goToDetail(id){
                this.$emit('getDetailId',id)
            }
		}
	}
</script>

