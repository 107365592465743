<template>
	<div>
        <inbox-table
            @getDetailId="goToDetail"
            v-if="currentBox == 0"></inbox-table>
        <outbox-table
            @getDetailId="goToDetail"
            v-if="currentBox == 1"></outbox-table>
    </div>
</template>
<script>
import InboxTable from "./InboxTable";
import OutboxTable from "./OutboxTable";

	export default {
        props: {
            currentBox: {
                type: Number,
                default: 0
            }
        },
		components: {
            InboxTable,
            OutboxTable
		},

		data() {
			return {

			}
		},
		created() {

		},
        watch: {

        },
		methods: {
            goToDetail(id){
                this.$emit('getDetailId',id)
            }
		}
	}
</script>

