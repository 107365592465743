import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.get('/messages', config);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.post('/messages', formData);
}

const getGroups = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/message/groups', config);
}

const userInbox = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.get('/message/receivers/me', config);
}

const showMessage = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.get('/messages/show/'+id);
}

const userOutbox = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.get('/messages/sent', config);
}

const answerMessage = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.post('/messages/answer', formData);
}

const deleteMessage = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.delete('/messages/'+id);
}

const showMedia = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_MESSAGE;
    return Api.get('/messages/media/'+id, {responseType: 'arraybuffer'});
}

export default {
    getAll,
    store,
    getGroups,
    userInbox,
    showMessage,
    userOutbox,
    answerMessage,
    deleteMessage,
    showMedia
}
